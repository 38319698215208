import React, { Fragment, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  Container,
  Row,
  Col,
  FormText,
  Alert,
  UncontrolledAlert,
} from "reactstrap";
// core components

import { useToasts } from "react-toast-notifications";
import { checkSpace, postMethod, getMethod } from "../../services/httpServices";
import {
  checkDigitOnly,
  checkBankAccNo,
  checkName,
  checkBbanValidation,
} from "services/util";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MOBILE_ONLY_COUNTRY, CASH_TOPUP_HINT } from "../../appConfig";

const codes = [
  23225, 23230, 23231, 23232, 23233, 23234, 23244, 23275, 23276, 23277, 23278,
  23279, 23280, 23288, 23299,
];

const merchantAccountInitialState = {
  merchantName: "",
  fspId: "",
  fspName: "",
  accountId: "",
  id: 1,
};

const initialValue = {
  name: "",
  address: "",
  mobileCode: "",
  phone: "",
  phoneWithCC: "",
  email: "",
  bankName: "",
  bban: "",
  attachFile: "",
  apiUrl: "",
  uniqueLabel: "",
  commissionId: "",
  authType: "Basic",
  basicAuthUName: "",
  basicAuthPswd: "",
  validateUrl: "api/ValidateProduct/validate",
  updateStatusUrl: "api/ValidateProduct/updatePaymentStatus",
  bankAccNo: "",
  bankProfileId: "",
  cashTopupCommId: "",
  address1: "",
  address2: "",
  district: "",
  chiefdomOrArea: "",
  receivablesUse: "",
  onlineGateway: false,
  smartBill: false,
  fspCategory: "",
};

const Merchant = (props) => {
  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const { addToast } = useToasts();
  const [file, setFile] = React.useState(null);
  const [commissions, setCommission] = React.useState([]);
  const [cashTopUpComm, setCashTopUpComm] = React.useState([]);
  const [showPin, setShowPin] = React.useState(false);
  const [merchantBankAccounts, setMerchantBankAccounts] = React.useState([
    merchantAccountInitialState,
  ]);
  const [fsps, setFsps] = React.useState([]);
  const [verifyErrros, setVerifyErrors] = useState([]);

  React.useEffect(() => {
    fetchCommission();
    fetchCashTopUpCommission();
    fetchAllFsps();
  }, []);

  const fetchAllFsps = async () => {
    let errorMsg = "";
    setLoading(true);
    getMethod("/api/FSP/GetAll", "get")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data?.result?.length) {
              setFsps([]);
            } else {
              setFsps(res.data.result);
            }
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const verifyAccount = async (accountNumber, fspId) => {
    const payload = {
      accountNumber,
      fspId,
    };
    // return postMethod("/api/FSP/Account/verify", payload, "post");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      if (name === "phone") {
        if (checkMobValidation(value)) {
          changeState({ ...values, [name]: value });
        }
      } else if (name === "bban") {
        if (checkBbanValidation(value)) {
          changeState({ ...values, [name]: value });
        }
      } else if (name === "name") {
        if (checkName(value)) {
          changeState({ ...values, [name]: value });
        }
      } else if (name === "bankAccNo") {
        if (checkBankAccNo(value)) {
          changeState({ ...values, [name]: value });
        }
      } else {
        changeState({ ...values, [name]: value });
      }
    }
  };

  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      changeState({
        ...values,
        phoneWithCC: value,
        mobileCode: data.dialCode,
        phone: value.substring(data.dialCode.length),
      });
    }
    return;
  };

  const changeState = (obj) => {
    setValues(obj);
  };

  const checkMobValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 6) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const closeModal = () => {
    props.closeModal();
  };

  const validate = (inputs) => {
    let errors = {};
    let emailRegEx = /\S+@\S+\.\S+/;
    let urlRegEx =
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    if (!inputs.name) {
      errors.name = "Merchant Name is Required";
    }
    if (!inputs.email) {
      errors.email = "Email is Required";
    } else if (!emailRegEx.test(inputs.email)) {
      errors.email = "Enter valid email";
    }
    // if (!inputs.bban) {
    //   errors.bban = "BBAN is Required";
    // } else if (inputs.bban.length < 8 || inputs.bban.length > 18) {
    //   errors.bban = "BBAN should have 8 to 18 digits";
    // }
    if (!inputs.mobileCode) {
      errors.mobileCode = "Please select mobile code";
    }
    if (!inputs.phone) {
      errors.phone = "Phone Number is Required";
    }
    // else if (inputs.phone.length !== 6) {
    //   errors.phone = 'Phone Number should have 6 digits';
    // }
    // if (!inputs.bankName) {
    //   errors.bankName = "Bank Name is Required";
    // }
    // if (!inputs.bankProfileId) {
    //   errors.bankProfileId = "Bank Name is Required";
    // }
    // if (!inputs.address) {
    //   errors.address = 'Address is Required';
    // }
    if (!inputs.address1) {
      errors.address1 = "Address Line 1 is Required";
    }
    // if (!inputs.address2) {
    //   errors.address2 = 'Address2 is Required';
    // }
    // if (!inputs.chiefdomOrArea) {
    //   errors.chiefdomOrArea = 'chiefdomOrArea is Required';
    // }
    // if (!inputs.receivablesUse) {
    //   errors.receivablesUse = 'chiefdomOrArea is Required';
    // }
    if (!file) {
      errors.attachFile = "Attach file is required";
    }
    if (!inputs.apiUrl) {
      errors.apiUrl = "Api url is required";
    } else if (!urlRegEx.test(inputs.apiUrl)) {
      errors.apiUrl = "Please enter valid api url";
    }
    if (!inputs.uniqueLabel) {
      errors.uniqueLabel = "Unique reference label is required";
    }
    if (!inputs.commissionId) {
      errors.commissionId = "Please select commission";
    }
    if (!inputs.validateUrl) {
      errors.validateUrl = "Please enter validate product url";
    }
    if (!inputs.updateStatusUrl) {
      errors.updateStatusUrl = "Please enter update payment status url";
    }
    if (!inputs.basicAuthPswd) {
      errors.basicAuthPswd = "Please enter basic authentication password";
    }
    if (!inputs.basicAuthUName) {
      errors.basicAuthUName = "Please enter basic authentication user name";
    }
    // if (!inputs.bankAccNo) {
    //   errors.bankAccNo = "Please enter bank account number";
    // } else if (inputs.bankAccNo.length < 8 || inputs.bankAccNo.length > 18) {
    //   errors.bankAccNo = "Please enter 8 to 18 digit Bank Account Number";
    // }
    return errors;
  };

  const fileChanger = (event) => {
    setFile(event.target.files[0]);
    // setErrors({ ...errors, attachFile: "" });
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };


  console.log({errors})
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      // const verifyAll = merchantBankAccounts.map((item) =>
      //   verifyAccount(item.accountId, item.fspId)
      // );
      // setLoading(true);

      // const allProms = await Promise.all(verifyAll);
      // setLoading(false);
      // const promResults = allProms.map((i) => i.data?.responseCode);
      // setVerifyErrors(promResults);
      // if (promResults.includes("400")) {
      //   notifyFailed(
      //     "Error while verifying bank accounts. Please check bank account id."
      //   );
      //   return;
      // }
      var data = new FormData();
      data.append("Name", values.name.trim());
      data.append("Address", values.address);
      data.append("PhoneNumber", values.phone);
      data.append("MobileCode", values.mobileCode);
      data.append("Email", values.email);
      data.append("BankName", values.bankName);
      data.append("BBAN", values.bban ?? "");
      // data.append('Status', values.itPhone);
      data.append("Attachment", file);
      data.append("WebSiteURL", values.apiUrl);
      data.append("LabelKey", values.uniqueLabel);
      data.append("MerchantType", "Online");
      data.append("CommissionId", values?.commissionId ?? "");
      data.append("WebSiteAuthenticationType", "basic");
      data.append("WebSitePassword", values.basicAuthPswd);
      data.append("WebSiteUser", values.basicAuthUName);
      data.append("MerchantAPIConfig[0].MethodName", "Validate");
      data.append("MerchantAPIConfig[0].MethodURL", values.validateUrl);
      data.append("MerchantAPIConfig[1].MethodName", "Payment");
      data.append("MerchantAPIConfig[1].MethodURL", values.updateStatusUrl);
      data.append("BankAccountNumber", values.bankAccNo ?? "");
      data.append("BankProfileId", 0);
      data.append(
        "TopUpCommissionId",
        values.cashTopupCommId ? values.cashTopupCommId : 0
      );
      data.append("AddressLine1", values.address1);
      data.append("AddressLine2", values.address2);
      data.append("ChiefdomOrArea", values.chiefdomOrArea);
      data.append("District", values.district);
      data.append("ReceivablesUse", values.receivablesUse);
      data.append("OnlineGateway", values.onlineGateway === "Yes");
      data.append("SmartBill", values.smartBill === "Yes");

      merchantBankAccounts.forEach((i, idx) => {
        data.append(
          `MerchantBankAccounts[${idx}].MerchantName`,
          values.name.trim()
        );
        data.append(`MerchantBankAccounts[${idx}].FSPId`, i.fspId);
        data.append(`MerchantBankAccounts[${idx}].FSPName`, i.fspName);
        data.append(`MerchantBankAccounts[${idx}].AccountId`, i.accountId);
      });

      let errorMsg = "";
      setLoading(true);
      postMethod("/api/MerchantProfile/save", data, "post")
        .then((res) => {
          setLoading(false);
          if (res && res.data) {
            if (res.data.responseCode === "200") {
              notifySuccess("Saved Successfully");
              closeModal();
            } else if (res.data.responseCode === "400") {
              // Error while saving
              errorMsg = res.data.responseMessage || "Error while saving";
              notifyFailed(errorMsg);
            } else {
              // Unknown Error
              errorMsg = "Unknown Error in saving";
              notifyFailed(errorMsg);
            }
          } else {
            // Not valid response from server
            errorMsg = "Unknown Error";
            notifyFailed(errorMsg);
          }
        })
        .catch((err) => {
          setLoading(false);
          errorMsg = "Something went wrong!, Please call 3737";
          notifyFailed(errorMsg);
        });
    }
  };

  const fetchCommission = () => {
    let errorMsg = "";
    getMethod("/api/commission/ddllist?CommissionType=Merchant", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setCommission(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg =
              res.data.responseMessage || "Error in fetching commission";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const fetchCashTopUpCommission = () => {
    let errorMsg = "";
    getMethod("/api/commission/ddllist?CommissionType=CashTopUp", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setCashTopUpComm(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg =
              res.data.responseMessage || "Error in fetching commission";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const onAccChange = (e, idx) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      const updated = structuredClone(merchantBankAccounts);
      updated[idx][name] = value;
      if (name === "fspId") {
        updated[idx].fspName = fsps.find((i) => i.id == value)?.fspName;
      }
      setMerchantBankAccounts(updated);
    }
  };

  return (
    <>
      {/* Page content */}
      <div className="p-0">
        <Row>
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Merchant Information</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={closeModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: "pointer", fontSize: "25px" }}
                    ></Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Merchant Name *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-name"
                            placeholder="Merchant Name"
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleInputChange}
                          />
                          {errors.name && (
                            <div className="text-left text-danger">
                              <small>* {errors.name}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="jesse@example.com"
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleInputChange}
                          />
                          {errors.email && (
                            <div className="text-left text-danger">
                              <small>* {errors.email}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bankphone"
                          >
                            Phone Number *
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            country={"sl"}
                            name="phoneWithCC"
                            value={values.phoneWithCC}
                            // onlyCountries={MOBILE_ONLY_COUNTRY}
                            countryCodeEditable={false}
                            onChange={phoneChange}
                          />
                          {errors.phone && (
                            <div className="text-left text-danger">
                              <small>* {errors.phone}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address1"
                          >
                            Address Line 1 *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-address1"
                            placeholder="Address Line 1"
                            type="text"
                            name="address1"
                            required
                            value={values.address1}
                            onChange={handleInputChange}
                          />
                          {errors.address1 && (
                            <div className="text-left text-danger">
                              <small>* {errors.address1}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address2"
                          >
                            Address Line 2
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-address2"
                            placeholder="Address Line 2"
                            type="text"
                            name="address2"
                            value={values.address2}
                            onChange={handleInputChange}
                          />
                          {errors.address2 && (
                            <div className="text-left text-danger">
                              <small>* {errors.address2}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-chiefdomOrArea"
                          >
                            Chiefdom Or Area
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-chiefdomOrArea"
                            placeholder="Chiefdom Or Area"
                            type="text"
                            name="chiefdomOrArea"
                            value={values.chiefdomOrArea}
                            onChange={handleInputChange}
                          />
                          {errors.chiefdomOrArea && (
                            <div className="text-left text-danger">
                              <small>* {errors.chiefdomOrArea}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-district"
                          >
                            District
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-district"
                            placeholder="District"
                            type="text"
                            name="district"
                            value={values.district}
                            onChange={handleInputChange}
                          />
                          {errors.district && (
                            <div className="text-left text-danger">
                              <small>* {errors.district}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-receivablesUse"
                          >
                            Receivables Use
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-receivablesUse"
                            placeholder="ReceivablesUse"
                            type="text"
                            name="receivablesUse"
                            value={values.receivablesUse}
                            onChange={handleInputChange}
                          />
                          {errors.receivablesUse && (
                            <div className="text-left text-danger">
                              <small>* {errors.receivablesUse}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            htmlFor="onlineGateway"
                            className="form-control-label"
                          >
                            Online Gateway
                          </label>
                          <Input
                            type="select"
                            id="onlineGateway"
                            className="form-control-alternative"
                            name="onlineGateway"
                            value={values.onlineGateway}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">
                              -----Select Online Gateway ------
                            </option>
                            {[
                              { key: "Yes", value: "Yes" },
                              { key: "No", value: "No" },
                            ].map((c) => (
                              <option key={c.key} value={c.key}>
                                {c.value}
                              </option>
                            ))}
                          </Input>
                          {errors.onlineGateway && (
                            <div className="text-left text-danger">
                              <small>* {errors.onlineGateway}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            htmlFor="smartBill"
                            className="form-control-label"
                          >
                            SmartBill
                          </label>
                          <Input
                            type="select"
                            id="smartBill"
                            className="form-control-alternative"
                            name="smartBill"
                            value={values.smartBill}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">
                              -----Select SmartBill ------
                            </option>
                            {[
                              { key: "Yes", value: "Yes" },
                              { key: "No", value: "No" },
                            ].map((c) => (
                              <option key={c.key} value={c.key}>
                                {c.value}
                              </option>
                            ))}
                          </Input>
                          {errors.SmartBill && (
                            <div className="text-left text-danger">
                              <small>* {errors.SmartBill}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      {/* <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-address">
                          Address
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-address"
                          placeholder="Addess"
                          // type="text"
                          // rows="4"
                          type="textarea"
                          rows="4"
                          name="address"
                          value={values.address}
                          onChange={handleInputChange}
                        />
                        {errors.address && <div className="text-left text-danger">
                          <small>* {errors.address}</small>
                        </div>}
                      </Col> */}
                    </Row>
                  </div>

                  <hr className="my-4" />

                  <h6 className="heading-small text-muted mb-4">
                    Authentication Configuration
                  </h6>

                  <div className="pl-lg-4">
                    <Row className="mt-3">
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-apiurl"
                        >
                          Api Integration Url *
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-apiurl"
                          placeholder="Api Integration Url"
                          type="text"
                          name="apiUrl"
                          value={values.apiUrl}
                          onChange={handleInputChange}
                        />
                        {errors.apiUrl && (
                          <div className="text-left text-danger">
                            <small>* {errors.apiUrl}</small>
                          </div>
                        )}
                      </Col>

                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-uniqueLabel"
                        >
                          Unique Identification Label *
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-uniqueLabel"
                          placeholder="Unique Identification Label"
                          type="text"
                          name="uniqueLabel"
                          value={values.uniqueLabel}
                          onChange={handleInputChange}
                        />
                        {errors.uniqueLabel && (
                          <div className="text-left text-danger">
                            <small>* {errors.uniqueLabel}</small>
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-validate-product-url"
                        >
                          Validate Product Url
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-validate-product-url"
                          type="text"
                          name="validateUrl"
                          value={values.validateUrl}
                          onChange={handleInputChange}
                        />
                        {errors.validateUrl && (
                          <div className="text-left text-danger">
                            <small>* {errors.validateUrl}</small>
                          </div>
                        )}
                      </Col>

                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-payment-status-url"
                        >
                          Update Payment Status Url
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-payment-status-url"
                          type="text"
                          name="updateStatusUrl"
                          value={values.updateStatusUrl}
                          onChange={handleInputChange}
                        />
                        {errors.updateStatusUrl && (
                          <div className="text-left text-danger">
                            <small>* {errors.updateStatusUrl}</small>
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Basic Auth Username *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            type="text"
                            placeholder="Enter Username"
                            name="basicAuthUName"
                            value={values.basicAuthUName}
                            onChange={handleInputChange}
                          />
                          {errors.basicAuthUName && (
                            <div className="text-left text-danger">
                              <small>* {errors.basicAuthUName}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-pswd"
                          >
                            Basic Auth Password *
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              id="input-pswd"
                              type={showPin ? "password" : "text"}
                              name="basicAuthPswd"
                              placeholder="Enter Password"
                              value={values.basicAuthPswd}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                            {!showPin && (
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i
                                    className="fa fa-eye-slash"
                                    onClick={() => setShowPin(true)}
                                  />
                                </InputGroupText>
                              </InputGroupAddon>
                            )}

                            {showPin && (
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i
                                    className="fa fa-eye"
                                    onClick={() => setShowPin(false)}
                                  />
                                </InputGroupText>
                              </InputGroupAddon>
                            )}
                          </InputGroup>
                          {errors.basicAuthPswd && (
                            <div className="text-left text-danger">
                              <small>* {errors.basicAuthPswd}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Commission Information
                  </h6>

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="commission"
                            className="form-control-label"
                          >
                            Commission
                          </label>
                          <Input
                            type="select"
                            id="commission"
                            className="form-control-alternative"
                            name="commissionId"
                            value={values.commissionId}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">
                              -----Select Commission ------
                            </option>
                            {commissions.map((c) => (
                              <option key={c.key} value={c.key}>
                                {c.value}
                              </option>
                            ))}
                          </Input>
                          {errors.commissionId && (
                            <div className="text-left text-danger">
                              <small>* {errors.commissionId}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      {/* <Col lg="6">
                        <FormGroup>
                          <label
                            for="commission"
                            className="form-control-label"
                          >
                            Cash Topup Commission
                          </label>
                          <Input
                            type="select"
                            id="commission"
                            className="form-control-alternative"
                            name="cashTopupCommId"
                            value={values.cashTopupCommId}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">
                              -----Select Commission ------
                            </option>
                            {cashTopUpComm.map((c) => (
                              <option key={c.key} value={c.key}>
                                {c.value}
                              </option>
                            ))}
                          </Input>
                          <div className="text-left">
                            <small>{CASH_TOPUP_HINT}</small>
                          </div>
                        </FormGroup>
                      </Col> */}
                    </Row>
                  </div>

                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Merchant Bank Account Details (settlement)
                  </h6>

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        {verifyErrros.includes("400") && (
                          <UncontrolledAlert
                            style={{
                              backgroundColor: "lightpink",
                              color: "red",
                            }}
                            color="danger"
                          >
                            If merchants accounts are not verified then it will
                            be difficult to do the settlement.
                          </UncontrolledAlert>
                        )}
                      </Col>
                      {merchantBankAccounts.map((acc, idx) => (
                        <Fragment key={idx}>
                          {merchantBankAccounts.length > 1 && (
                            <Col lg="12">
                              {" "}
                              <h5 className="heading-small text-muted mb-2 pb-2 border-bottom">
                                Account {idx + 1}
                              </h5>
                            </Col>
                          )}
                          <br />
                          <Col lg="6">
                            <FormGroup>
                              <label
                                for="input-fsp"
                                className="form-control-label"
                              >
                                FSP *
                              </label>
                              <Input
                                type="select"
                                id="input-fspId"
                                className="form-control-alternative"
                                name="fspId"
                                required
                                value={acc.fspId}
                                onChange={(e) => {
                                  onAccChange(e, idx, acc.id);
                                }}
                              >
                                <option value="">-----Select FSP-----</option>
                                {fsps &&
                                  fsps.map((c) => (
                                    <option key={c.id} value={c.id}>
                                      {c.fspName}
                                    </option>
                                  ))}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg={"6"} className="w-100">
                            <div className="d-flex align-items-center w-100">
                              <FormGroup className="w-100">
                                <label
                                  className="form-control-label d-flex flex-items-middle justify-content-between"
                                  htmlFor="input-accountId"
                                >
                                  Merchant bank account number *
                                  {verifyErrros[idx] === "400" && (
                                    <div className="text-left text-danger mb-1">
                                      <small>Enter valid account number</small>
                                    </div>
                                  )}
                                  {verifyErrros[idx] === "200" && (
                                    <div className="text-left text-success mb-1">
                                      <small>Verified!</small>
                                    </div>
                                  )}
                                </label>
                                <InputGroup className="input-group-alternative">
                                  <Input
                                    id="input-accountId"
                                    placeholder="Enter Merchant bank account number"
                                    type="text"
                                    name="accountId"
                                    required
                                    value={acc.accountId}
                                    onChange={(e) => onAccChange(e, idx)}
                                  />
                                  {verifyErrros[idx] === "200" ? (
                                    <InputGroupAddon
                                      addonType="append"
                                      title="Verified"
                                    >
                                      <InputGroupText>
                                        <i class="fas fa-check-circle text-green"></i>{" "}
                                      </InputGroupText>
                                    </InputGroupAddon>
                                  ) : verifyErrros[idx] === "400" ? (
                                    <InputGroupAddon
                                      addonType="append"
                                      title="Invalid Account"
                                    >
                                      <InputGroupText>
                                        <i class="fas fa-times-circle text-red"></i>{" "}
                                      </InputGroupText>
                                    </InputGroupAddon>
                                  ) : null}
                                </InputGroup>
                              </FormGroup>
                              {merchantBankAccounts.length > 1 && (
                                <Button
                                  className="btn float-right ml-2 mt-2"
                                  color="danger"
                                  type="button"
                                  style={{
                                    background: "crimson",
                                  }}
                                  disabled={loading}
                                  onClick={() => {
                                    const filtered =
                                      merchantBankAccounts.filter(
                                        (_, j) => j !== idx
                                      );
                                    setMerchantBankAccounts(filtered);
                                  }}
                                >
                                  <i class="fas fa-trash-alt"></i>{" "}
                                </Button>
                              )}
                            </div>
                          </Col>
                        </Fragment>
                      ))}
                      <Col lg={"6"}></Col>
                      <Col lg={"6"}>
                        {" "}
                        <Button
                          className="btn float-right"
                          color="primary"
                          type="button"
                          disabled={loading}
                          onClick={() => {
                            setMerchantBankAccounts([
                              ...merchantBankAccounts,
                              {
                                ...merchantAccountInitialState,
                                id: merchantBankAccounts.length + 1,
                              },
                            ]);
                          }}
                        >
                          <i class="fas fa-user-plus pr-2"></i>
                          Add More
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg-6></Col>
                    </Row>
                    <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">
                      Merchant Document
                    </h6>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label" for="attachFile">
                          Attachment *
                        </label>
                        <Input
                          className="form-control-file"
                          type="file"
                          name="file"
                          id="attachFile"
                          accept="application/pdf"
                          onChange={fileChanger}
                        />
                        <span>* pdf only</span>
                        {errors.attachFile && (
                          <div className="text-left text-danger">
                            <small>* {errors.attachFile}</small>
                          </div>
                        )}
                        {/* <FormText color="muted">
                            This is some placeholder block-level help text for the above input.
                            It's a bit lighter and easily wraps to a new line.
                          </FormText> */}
                      </FormGroup>
                    </Col>
                    <hr className="my-4" />

                    <Row>
                      <Col lg-12>
                        <div>
                          <Button
                            className="btn float-right"
                            color="primary"
                            type="submit"
                            disabled={loading}
                          >
                            Submit{" "}
                            {loading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Merchant;
