import React, { Fragment, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import { useToasts } from "react-toast-notifications";
import { getMethod } from "../../services/httpServices";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MOBILE_ONLY_COUNTRY, CASH_TOPUP_HINT } from "../../appConfig";
import { checkBbanValidation } from "services/util";

const initialValue = {
  name: "",
  address: "",
  mobileCode: "",
  phone: "",
  phoneWithCC: "",
  email: "",
  bankName: "",
  bban: "",
  attachFile: "",
  apiUrl: "",
  uniqueLabel: "",
  commissionId: "",
  authType: "Basic",
  basicAuthUName: "",
  basicAuthPswd: "",
  validateUrl: "",
  updateStatusUrl: "",
  bankAccNo: "",
  userProfileId: "",
  bankProfileId: "",
  cashTopupCommId: "",
  address1: "",
  address2: "",
  district: "",
  chiefdomOrArea: "",
  receivablesUse: "",
  onlineGateway: false,
  smartBill: false,
};

const codes = [
  23225, 23230, 23231, 23232, 23233, 23234, 23244, 23275, 23276, 23277, 23278,
  23279, 23280, 23288, 23299,
];

const ViewMerchant = (props) => {
  initialValue.name = props.name;
  initialValue.address = props.address;
  initialValue.mobileCode = props.mobileCode;
  initialValue.phone = props.phoneNumber;
  initialValue.email = props.email;
  initialValue.bankName = props.bankName;
  initialValue.bban = props.bban;
  initialValue.apiUrl = props.webSiteURL;
  initialValue.uniqueLabel = props.labelKey;
  initialValue.commissionId = props.commissionId;
  initialValue.basicAuthUName = props.webSiteUser;
  initialValue.basicAuthPswd = props.webSitePassword;
  initialValue.bankAccNo = props.bankAccountNumber;
  initialValue.userProfileId = props.userProfileId;
  initialValue.bankProfileId = props.bankProfileId;
  initialValue.address1 = props.addressLine1;
  initialValue.address2 = props.addressLine2;
  initialValue.chiefdomOrArea = props.chiefdomOrArea;
  initialValue.district = props.district;
  initialValue.receivablesUse = props.receivablesUse;
  initialValue.onlineGateway = props.onlineGateway === true ? "Yes" : "No";
  initialValue.smartBill = props.smartBill === true ? "Yes" : "No";
  initialValue.merchantBankAccounts = props.merchantBankAccounts;

  initialValue.phoneWithCC = props.mobileCode + props.phoneNumber;
  initialValue.cashTopupCommId = props.topUpCommissionId
    ? props.topUpCommissionId
    : 0;
  if (props.merchantAPIConfig && props.merchantAPIConfig.length) {
    for (let i = 0; i < props.merchantAPIConfig.length; i++) {
      if (props.merchantAPIConfig[i].methodName == "Validate") {
        initialValue.validateUrl = props.merchantAPIConfig[i].methodURL;
      } else if (props.merchantAPIConfig[i].methodName == "Payment") {
        initialValue.updateStatusUrl = props.merchantAPIConfig[i].methodURL;
      }
    }
  }
  const [spinner, setSpinner] = React.useState(false);

  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [commissions, setCommission] = React.useState([]);
  const [showPin, setShowPin] = React.useState(false);
  const [cashTopUpComm, setCashTopUpComm] = React.useState([]);
  const [fsps, setFsps] = useState([]);
  const { addToast } = useToasts();

  const downloadFile = () => {
    let url = "/api/MerchantProfile/downloadFile/" + values.id;
    setSpinner(true);
    getMethod(url, "get")
      .then((res) => {
        setSpinner(false);
        if (res && res.config && res.config.url) {
          window.location.href = res.config.url;
        } else {
          notifyFailed("Error in fetching file from server");
        }
      })
      .catch((err) => {
        setSpinner(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  React.useEffect(() => {
    fetchCommission();
    fetchCashTopUpCommission();
    fetchAllFsps();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      if (checkMobValidation(value)) {
        setValues({ ...values, [name]: value });
      }
    } else if (name === "bban") {
      if (checkBbanValidation(value)) {
        setValues({ ...values, [name]: value });
      }
    } else {
      setValues({ ...values, [name]: value });
    }
    // const validationErrors = validate(values);
    // setErrors(validationErrors);
  };

  const checkMobValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 5) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const closeModal = () => {
    props.closeModal();
  };

  const fetchAllFsps = async () => {
    let errorMsg = "";
    getMethod("/api/FSP/GetAll", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data?.result?.length) {
              setFsps([]);
            } else {
              setFsps(res.data.result);
            }
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const validate = (inputs) => {
    let errors = {};
    let emailRegEx = /\S+@\S+\.\S+/;
    if (!inputs.name) {
      errors.name = "Merchant Name is Required";
    }
    if (!inputs.email) {
      errors.email = "Email is Required";
    } else if (!emailRegEx.test(inputs.email)) {
      errors.email = "Enter valid email";
    }
    if (!inputs.bban) {
      errors.bban = "BBAN is Required";
    } else if (inputs.bban.length < 8 || inputs.bban.length > 18) {
      errors.bban = "BBAN should have 8 to 18 digits";
    }
    if (!inputs.mobileCode) {
      errors.mobileCode = "Please select mobile code";
    }
    if (!inputs.phone) {
      errors.phone = "Phone Number is Required";
    } else if (inputs.phone.length !== 5) {
      errors.phone = "Phone Number should have 5 digits";
    }
    if (!inputs.bankName) {
      errors.bankName = "Bank Name is Required";
    }
    if (!inputs.address) {
      errors.address = "Address is Required";
    }
    if (!inputs.attachFile) {
      errors.attachFile = "Attach file is required";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const fetchCommission = () => {
    let errorMsg = "";
    getMethod("/api/commission/ddllist?CommissionType=Merchant", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setCommission(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg =
              res.data.responseMessage || "Error in fetching commission";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const fetchCashTopUpCommission = () => {
    let errorMsg = "";
    getMethod("/api/commission/ddllist?CommissionType=CashTopUp", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setCashTopUpComm(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg =
              res.data.responseMessage || "Error in fetching commission";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  return (
    <>
      {/* Page content */}
      <div className="p-0">
        <Row>
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Merchant Information</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={closeModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: "pointer", fontSize: "25px" }}
                    ></Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Merchant Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-name"
                            placeholder="Merchant Name"
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleInputChange}
                            disabled
                          />
                          {errors.name && (
                            <div className="text-left text-danger">
                              <small>* {errors.name}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="jesse@example.com"
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleInputChange}
                            disabled
                          />
                          {errors.email && (
                            <div className="text-left text-danger">
                              <small>* {errors.email}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col lg="2">
                        <FormGroup>
                          <label
                            for="input-mobileCode"
                            className="form-control-label">
                            Mobile Code
                          </label>
                          <Input
                            type="select"
                            id="input-mobileCode"
                            className="form-control-alternative"
                            name="mobileCode"
                            value={values.mobileCode}
                            onChange={handleInputChange}
                            disabled
                          >
                            {codes.map(c => <option key={c}>{c}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-phone"
                            placeholder="Phone Number"
                            type="text"
                            name="phone"
                            value={values.phone}
                            onChange={handleInputChange}
                            disabled
                          />
                          {errors.phone && <div className="text-left text-danger">
                            <small>* {errors.phone}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                       */}

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bankphone"
                          >
                            Phone Number
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            country={"sl"}
                            name="phoneWithCC"
                            value={values.phoneWithCC}
                            // onlyCountries={MOBILE_ONLY_COUNTRY}
                            countryCodeEditable={false}
                            disabled
                          />
                          {errors.phone && (
                            <div className="text-left text-danger">
                              <small>* {errors.phone}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      {/* <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-address">
                          Address
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-address"
                          placeholder="Addess"
                          // type="text"
                          // rows="4"
                          type="textarea"
                          rows="4"
                          name="address"
                          value={values.address}
                          onChange={handleInputChange}
                          disabled
                        />
                        {errors.address && <div className="text-left text-danger">
                          <small>* {errors.address}</small>
                        </div>}
                      </Col> */}
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address1"
                          >
                            Address Line 1
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-address1"
                            placeholder="Address Line 1"
                            type="text"
                            name="address1"
                            disabled
                            value={values.address1}
                            onChange={handleInputChange}
                          />
                          {errors.address1 && (
                            <div className="text-left text-danger">
                              <small>* {errors.address1}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address2"
                          >
                            Address Line 2
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-address2"
                            placeholder="Address Line 2"
                            type="text"
                            disabled
                            name="address2"
                            value={values.address2}
                            onChange={handleInputChange}
                          />
                          {errors.address2 && (
                            <div className="text-left text-danger">
                              <small>* {errors.address2}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-chiefdomOrArea"
                          >
                            ChiefdomOrArea
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-chiefdomOrArea"
                            placeholder="Chiefdom Or Area"
                            type="text"
                            disabled
                            name="chiefdomOrArea"
                            value={values.chiefdomOrArea}
                            onChange={handleInputChange}
                          />
                          {errors.chiefdomOrArea && (
                            <div className="text-left text-danger">
                              <small>* {errors.chiefdomOrArea}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-district"
                          >
                            District
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-district"
                            disabled
                            placeholder="District"
                            type="text"
                            name="district"
                            value={values.district}
                            onChange={handleInputChange}
                          />
                          {errors.district && (
                            <div className="text-left text-danger">
                              <small>* {errors.district}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-receivablesUse"
                          >
                            ReceivablesUse
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-receivablesUse"
                            placeholder="ReceivablesUse"
                            type="text"
                            disabled
                            name="receivablesUse"
                            value={values.receivablesUse}
                            onChange={handleInputChange}
                          />
                          {errors.receivablesUse && (
                            <div className="text-left text-danger">
                              <small>* {errors.receivablesUse}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            htmlFor="onlineGateway"
                            className="form-control-label"
                          >
                            Online Gateway
                          </label>
                          <Input
                            type="select"
                            id="onlineGateway"
                            className="form-control-alternative"
                            name="onlineGateway"
                            disabled
                            value={values.onlineGateway}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">
                              -----Select Online Gateway ------
                            </option>
                            {[
                              { key: "Yes", value: "Yes" },
                              { key: "No", value: "No" },
                            ].map((c) => (
                              <option key={c.key} value={c.key}>
                                {c.value}
                              </option>
                            ))}
                          </Input>
                          {errors.onlineGateway && (
                            <div className="text-left text-danger">
                              <small>* {errors.onlineGateway}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            htmlFor="smartBill"
                            className="form-control-label"
                          >
                            SmartBill
                          </label>
                          <Input
                            type="select"
                            id="smartBill"
                            className="form-control-alternative"
                            name="smartBill"
                            disabled
                            value={values.smartBill}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">
                              -----Select SmartBill ------
                            </option>
                            {[
                              { key: "Yes", value: "Yes" },
                              { key: "No", value: "No" },
                            ].map((c) => (
                              <option key={c.key} value={c.key}>
                                {c.value}
                              </option>
                            ))}
                          </Input>
                          {errors.SmartBill && (
                            <div className="text-left text-danger">
                              <small>* {errors.SmartBill}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />

                  <h6 className="heading-small text-muted mb-4">
                    Authentication Configuration
                  </h6>

                  <div className="pl-lg-4">
                    <Row className="mt-3">
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-apiurl"
                        >
                          Api Integration Url
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-apiurl"
                          placeholder="Api Integration Url"
                          type="text"
                          name="apiUrl"
                          value={values.apiUrl}
                          onChange={handleInputChange}
                          disabled
                        />
                        {errors.apiUrl && (
                          <div className="text-left text-danger">
                            <small>* {errors.apiUrl}</small>
                          </div>
                        )}
                      </Col>

                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-uniqueLabel"
                        >
                          Unique Identification Label
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-uniqueLabel"
                          placeholder="Unique Identification Label"
                          type="text"
                          name="uniqueLabel"
                          value={values.uniqueLabel}
                          onChange={handleInputChange}
                          disabled
                        />
                        {errors.uniqueLabel && (
                          <div className="text-left text-danger">
                            <small>* {errors.uniqueLabel}</small>
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-validate-product-url"
                        >
                          Validate Product Url
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-validate-product-url"
                          type="text"
                          name="validateUrl"
                          value={values.validateUrl}
                          onChange={handleInputChange}
                          disabled
                        />
                        {errors.validateUrl && (
                          <div className="text-left text-danger">
                            <small>* {errors.validateUrl}</small>
                          </div>
                        )}
                      </Col>

                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-payment-status-url"
                        >
                          Update Payment Status Url
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-payment-status-url"
                          type="text"
                          name="updateStatusUrl"
                          value={values.updateStatusUrl}
                          onChange={handleInputChange}
                          disabled
                        />
                        {errors.updateStatusUrl && (
                          <div className="text-left text-danger">
                            <small>* {errors.updateStatusUrl}</small>
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Basic Auth Username
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            type="text"
                            placeholder="Enter Username"
                            name="basicAuthUName"
                            value={values.basicAuthUName}
                            onChange={handleInputChange}
                            disabled
                          />
                          {errors.basicAuthUName && (
                            <div className="text-left text-danger">
                              <small>* {errors.basicAuthUName}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-pswd"
                          >
                            Basic Auth Password
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              id="input-pswd"
                              type={showPin ? "text" : "password"}
                              name="basicAuthPswd"
                              placeholder="Enter Password"
                              value={values.basicAuthPswd}
                              onChange={handleInputChange}
                              disabled
                            />
                            {!showPin && (
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i
                                    className="fa fa-eye"
                                    onClick={() => setShowPin(true)}
                                  />
                                </InputGroupText>
                              </InputGroupAddon>
                            )}

                            {showPin && (
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i
                                    className="fa fa-eye-slash"
                                    onClick={() => setShowPin(false)}
                                  />
                                </InputGroupText>
                              </InputGroupAddon>
                            )}
                          </InputGroup>
                          {errors.basicAuthPswd && (
                            <div className="text-left text-danger">
                              <small>* {errors.basicAuthPswd}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-userid"
                          >
                            Merchant User Id
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              id="input-userid"
                              type="text"
                              name="userProfileId"
                              value={values.userProfileId}
                              onChange={handleInputChange}
                              disabled
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Commission Information
                  </h6>

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="commission"
                            className="form-control-label"
                          >
                            Commission
                          </label>
                          <Input
                            type="select"
                            id="commission"
                            className="form-control-alternative"
                            name="commissionId"
                            value={values.commissionId}
                            onChange={handleInputChange}
                            disabled
                          >
                            <option key="" value="">
                              -----Select Commission ------
                            </option>
                            {commissions.map((c) => (
                              <option key={c.key} value={c.key}>
                                {c.value}
                              </option>
                            ))}
                          </Input>
                          {errors.commissionId && (
                            <div className="text-left text-danger">
                              <small>* {errors.commissionId}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Merchant Bank Account Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      {values?.merchantBankAccounts.map((acc, idx) => (
                        <Fragment key={idx}>
                          {values?.merchantBankAccounts.length > 1 && (
                            <Col lg="12">
                              {" "}
                              <h5 className="heading-small text-muted mb-2 pb-2 border-bottom">
                                Account {idx + 1}
                              </h5>
                            </Col>
                          )}
                          <br />
                          <Col lg="6">
                            <FormGroup>
                              <label
                                for="input-fsp"
                                className="form-control-label"
                              >
                                FSP
                              </label>
                              <Input
                                type="select"
                                id="input-fspId"
                                className="form-control-alternative"
                                name="fspId"
                                required
                                value={acc.fspId}
                                disabled
                              >
                                <option value="">-----Select FSP-----</option>
                                {fsps &&
                                  fsps.map((c) => (
                                    <option key={c.id} value={c.id}>
                                      {c.fspName}
                                    </option>
                                  ))}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg={"6"} className="w-100">
                            <div className="d-flex align-items-center w-100">
                              <FormGroup className="w-100">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-accountId"
                                >
                                  Account ID
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-accountId"
                                  placeholder="Account ID"
                                  type="text"
                                  name="accountId"
                                  disabled
                                  value={acc.accountId}
                                />
                              </FormGroup>
                            </div>
                          </Col>
                        </Fragment>
                      ))}
                      <Col lg={"6"}></Col>
                      <Col lg={"6"}> </Col>
                    </Row>

                    <Row>
                      <h6 className="heading-small text-muted mb-4">
                        Merchant Document
                      </h6>
                      <br />
                      <Col lg="6" className="mt-4">
                        <FormGroup>
                          <div style={{ marginTop: "20px" }}>
                            <a
                              href="#"
                              onClick={downloadFile}
                              style={{ marginTop: "20px" }}
                              id="tag"
                            >
                              Download Attached Document
                            </a>
                            &nbsp; &nbsp;
                            {spinner && (
                              <div class="spinner-border" role="status">
                                <span class="sr-only"></span>
                              </div>
                            )}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewMerchant;
